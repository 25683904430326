'use strict';

angular.module('cpformplastApp.items')
  .controller('itemCreationController', function ($scope, Modal, DataManager, $state, Notification, $modal, Auth) {

    const PreviewModeOnlyRoles = ["salesmen_dept"]
    $scope.item = {
      appropriateMachinesId: []
    };
    $scope.categories = [];
    $scope.machines = [];
    $scope.isPreviewMode = false
    $scope.clients = [];
    $scope.uniqueCategories = [];
    $scope.gage = undefined;
    $scope.width = undefined;
    $scope.adjustedWidth = undefined;
    $scope.differentDie = false;
    $scope.submitted = false;
    $scope.adjustedPoundsPerUnit = undefined;
    let initialSpeed = 1;

    $scope.init = function () {
      $scope.setIsPreviewMode();
      DataManager.fetchCategories(true)
        .then(data => {
          $scope.categories = data;

          if ($state.params.itemId) {
            DataManager.fetchItem($state.params.itemId).then(function (item) {
              $scope.item = item;
              $scope.gage = item.category.dimensions.gage;
              $scope.width = item.category.dimensions.width;
              $scope.differentDie = item.die !== '' && item.die !== undefined && item.die !== item.mold;
              $scope.adjustedWidth = item.category.adjustedWidth;
              $scope.adjustedPoundsPerUnit = item.poundsPerUnit * 1000;
              initialSpeed = item.speed;
            }, function (err) {
              console.log(err);
            });
          }
        })
        .catch(err => {
          console.log(err);
          Notification.error("Un problème est survenu lors du chargement des catégories");
        });

      DataManager.fetchMachines()
        .then(machines => {
          $scope.machines = machines;
        })
        .catch(err => {
          console.log(err);
          Notification.error("Un problème est survenu lors du chargement des machines");
        });

      DataManager.fetchClients()
        .then(clients => {
          $scope.clients = clients;
        })
        .catch(err => {
          console.log(err);
          Notification.error("Un problème est survenu lors du chargement des clients");
        });
    };

    $scope.setIsPreviewMode = function () {
      Auth.getCurrentUser(function (user, err) {
        $scope.isPreviewMode = PreviewModeOnlyRoles.findIndex(x => x === user.role) !== -1
      })
    }
    $scope.submitItem = function (form, create, message = "enregistrée") {

      $scope.submitted = true;
      if (!form.$valid) {
        Notification.error('Champ(s) invalide(s)');
        return false;
      }

      if (!$scope.item.client._id) {
        Notification.error('Client invalide');
        return false;
      }

      $scope.getCategoryId();
      $scope.item.die = $scope.differentDie ? $scope.item.die : $scope.item.mold;

      var promise;
      if (!$scope.item._id) {
        promise = DataManager.createItem($scope.item);
      } else {
        promise = DataManager.updateItem($scope.item);
      }

      promise.then((data) => {

        form.mold.$error.unique = false;
        form.mold.$valid = true;
        form.mold.$invalid = false;

        $scope.item._id = data._id;
        $state.params.itemId = data._id;

        Notification.success(`L'item <strong> ${$scope.item.name}</strong> fut ${message}`);

        if (create) {
          $state.go('items/item-management/item-creation', {'itemId': ''}, {reload: true});
        } else {
          $state.go('items/item-management/item-creation', {'itemId': $scope.item._id}, {reload: true});
        }

      })
        .catch(err => {
          if (err.data.name === 'ValidationError') {
            form.mold.$error.unique = true;
            form.mold.$valid = false;
            form.mold.$invalid = true;
          }
          Notification.error('Un problème est survenu');
        });

    };

    $scope.updateGageAndWidth = function () {

      $scope.categories.forEach(function (category) {
        if (category.title == $scope.item.category.title && category.dimension == $scope.item.category.dimension) {
          $scope.gage = category.dimensions.gage;
          $scope.width = category.dimensions.width;
          $scope.adjustedWidth = category.adjustedWidth;
          console.log($scope.adjustedWidth);
        }
      });

    };

    $scope.getCategoryId = function () {

      $scope.categories.forEach(function (category) {
        if (category.title == $scope.item.category.title && category.dimension == $scope.item.category.dimension) {
          $scope.item.category._id = category._id;
        }
      });

    };

    $scope.archive = function (archive, form) {
      $scope.item.archive = archive;
      $scope.submitItem(form, false, archive ? 'archivée' : 'réinitialisée');
    };

    $scope.filterForDimension = function () {
      let categories = $scope.categories,
        title, _id;

      try {
        title = $scope.item.category.title;
        _id = $scope.item.category._id;
      } catch (err) {
        name = undefined;
        _id = undefined;
      }

      return categories.filter(category => category.title === title && (!category.archive || category._id === _id)).sort((a, b) => a.dimension < b.dimension ? -1 : 1);
    };

    $scope.filterForTitle = function () {
      let categories = $scope.categories,
        filteredCategories = [],
        _id;

      try {
        _id = $scope.item.category._id;
      } catch (err) {
        _id = undefined;
      }

      categories
        .sort((a, b) => {
          if (a.archive && !b.archive) {
            return 1;
          } else if (!a.archive && b.archive) {
            return -1
          } else if (a.title > b.title) {
            return 1;
          } else if (a.title < b.title) {
            return -1;
          } else {
            return null;
          }
        })
        .forEach(category => {
          let foundedCat = filteredCategories.find(cat => cat.title === category.title);
          if (!foundedCat && (!category.archive || category._id === _id)) {
            filteredCategories.push(category);
          }
        });

      return filteredCategories;
    };

    $scope.isMachineActive = function (machine) {
      if (!machine) return false;
      if (!$scope.item) return false;
      if (!$scope.item.appropriateMachinesId) return false;
      return $scope.item.appropriateMachinesId.indexOf(machine.id) > -1;
    };

    $scope.toggleMachine = function (machine) {
      const index = $scope.item.appropriateMachinesId.indexOf(machine.id);
      if (index > -1) {
        $scope.item.appropriateMachinesId.splice(index, 1);
      } else {
        $scope.item.appropriateMachinesId.push(machine.id);
      }
    };

    $scope.openCategoryCreationModal = () => {
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: '../../components/modal/category/category-creation-modal.html',
        controller: 'CategoryCreationModal'
      });

      modalInstance.result.then(null, function () {
        DataManager.fetchCategories(true)
          .then(data => {
            $scope.categories = data;
          })
          .catch(err => {
            console.log(err);
          });
      });
    };

    $scope.print = () => {
      DataManager.downloadMoldCreationPdf($scope.item);
    };

    $scope.onSpeedChange = () => {
      // Allow empty field
      if ($scope.jobItems[0].speed == null) return;
      if (isNaN(parseInt($scope.item.speed))) $scope.item.speed = 1;
      else if (parseInt($scope.item.speed) < 1) $scope.item.speed = 1;
    };

    $scope.onSpeedBlur = () => {
      if ($scope.jobItems[0].speed === null) {
        $scope.jobItems[0].speed = initialSpeed;
      }
    }

    $scope.init();
  });
